import React from "react";
import Motorcycle from "../../../static/vehicles/gladius.jpg";

const ContentPatenteA3 = () => {
  return (
    <section id="patenteA3" className="text-gray-600 body-font">
      <div className="container px-8 sm:px-5 py-12 pb-56 mx-auto flex flex-col">
        <div className="lg:w-full mx-auto">
          <h1 className="text-center sm:text-6xl text-4xl font-roboto font-bold tracking-tight text-gray-900">
            Corso per patente A3
          </h1>
          <h2 className="lg:w-2/3 mx-auto mt-4 text-center sm:text-2xl text-xl font-sans font-normal tracking-tight text-gray-900">
            Con la patente A3, potrai guidare motocicli di potenza fino a 35 kW.
          </h2>
          <div className="flex flex-col sm:flex-row mt-4 sm:mt-10">
            <div className="sm:w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <div className="sm:mb-4 mb-4 px-8 py-2">
                <h2 className="text-center text-2xl font-roboto font-bold tracking-tight text-gray-900">
                  Mezzo utilizzato durante il corso
                </h2>
              </div>

              <div className="sm:w-full text-center sm:py-8 py-8">
                {/* Image 1 */}
                <div className="w-84 h-64 rounded-lg inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img
                    alt="Mezzo utilizzato per conseguire la patente A3"
                    className="w-full h-full rounded-xl block inset-0"
                    src={Motorcycle}
                  />
                </div>
                {/* Description 1 */}
                <div className="flex flex-col items-center text-center justify-center">
                  <div
                    className={`w-12 h-1 bg-gray-900 rounded mt-2 mb-4`}
                  ></div>
                  <h2 className="title-font font-roboto font-bold tracking-tight text-gray-900 text-lg px-16 py-2">
                    Suzuki Gladius 600
                  </h2>
                </div>
              </div>
            </div>
            <div className="order-first sm:order-last sm:w-full sm:pl-8 sm:py-8 sm:border-l sm:border-gray-400 sm:border-t-0 border-t border-gray-400 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <h2 className="text-3xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-gray-900">
                Età minima:
              </h2>
              <p className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                24 anni (oppure 20 anni + patente A2 da almeno 2 anni)
              </p>

              <h2 className="text-3xl title-font mb-4 font-roboto font-bold tracking-tight text-gray-900">
                Abilitazione guida dei seguenti veicoli e motoveicoli:
              </h2>
              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Permette di guidare tutti i motocicli senza limitazioni.
                </li>
              </ul>

              <h2 className="text-3xl title-font mt-16 font-roboto font-bold tracking-tight text-gray-900">
                Documenti necessari:
              </h2>
              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Cartà d'identità o patente
                </li>
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Permesso di soggiorno (solo per cittadini extra UE)
                </li>
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  2 fototessere
                </li>
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Certificato anamnestico (rilasciato dal medico curante)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentPatenteA3;
