import React from 'react';
import Layout from '../../components/layout/Layout';
import ListFeatureB from '../../components/ListFeatureB';
import ContentAM from '../../components/ContentAM';
import PageNavigator from "../../components/layout/PageNavigator";
import licenseA1 from '../../data/corsi-patente/patenti-A/licenseA1.json';
import licenseA2 from '../../data/corsi-patente/patenti-A/licenseA2.json';
import licenseA3 from '../../data/corsi-patente/patenti-A/licenseA3.json';

import { patentiA } from '../../data/corsi-patente/patenti-A/patentiA';
import ContentPatenteA1 from '../../components/corsi-patente/PatenteA1';
import ContentPatenteA2 from '../../components/corsi-patente/PatenteA2';
import ContentPatenteA3 from '../../components/corsi-patente/PatenteA3';
import Seo from '../../components/layout/SEO';
import BannerCompass from '../../components/BannerCompass';

const PatentiA = () => {
  return (
    <Layout>
      <Seo
        title="Corsi Patente per Motocicli | Autoscuola Faro, Pisa"
        description={"Corsi per patenti A1, A2 e A3, per il patentino AM o per la moto 125. Teoria e scuola guida in centro a Pisa. Ti aspettiamo!"}
        keywords={[
          "patente A1 Pisa",
          "patente A2 Pisa",
          "patente 125 Pisa",
          "autoscuola moto",
          "patente moto Pisa",
          "corsi patente A Pisa",
          "corso patente AM Pisa"
        ]}
      />
      <PageNavigator link="/corsi-patente" text="Corsi patenti" />
      <ListFeatureB list={patentiA} />

      <ContentPatenteA1 />
      <ContentPatenteA2 />
      <ContentPatenteA3 />

      {/* <ContentAM id="patenteA1" license={licenseA1} />
      <ContentAM id="patenteA2" license={licenseA2} />
      <ContentAM id="patenteA3" license={licenseA3} /> */}
      <BannerCompass />
    </Layout>
  )
};

export default PatentiA;
