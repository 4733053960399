import { faMotorcycle } from '@fortawesome/free-solid-svg-icons';
export const patentiA = {
    list: [
        {
            id: "pat1",
            title: "Patente A1",
            link: "/corsi-patente/patenti-A/#patenteA1",
            icon: faMotorcycle,
        },
        {
            id: "pat2",
            title: "Patente A2",
            link: "/corsi-patente/patenti-A/#patenteA2",
            icon: faMotorcycle,
        },
        {
            id: "pat3",
            title: "Patente A o A3",
            link: "/corsi-patente/patenti-A/#patenteA3",            
            icon: faMotorcycle,
        }
    ],
    buttonText: "Orario corsi"
}